<template>
        <div class="d-block d-lg-none">
      <div class="row">
        <div class="col-10 mb-0 col-sm-9 col-lg-10">
          <p class="mypracticeMobile" style="margin-left: 20px;">My Practice</p>
        </div>
        <div class="col-2 col-sm-3 col-lg-2">
          <p class="closeButton" @click="GoSetting()"><font-awesome-icon :icon="['fas','times']" /></p>
        </div>
      </div>
      <div class="hr-border" ></div>
    </div>
  <div class="mobileStyleDoctor">

      <div class="row clinicTextAlign" >
        <div class="clinic_section text-align text-left"  style="margin: 20px 0px">
            <div class="box-shadow2 cardBorder">
            <div class="row">
       </div>
        <div class="row" >
         <div class="col-12" >
                <div class="form-group my-2">
                    <label class="receiveConsultation">Clinic website</label>
                    <input type="text" v-model="this.clinic_website" class="form-control" name="clinic_website"
                       id="clinic_website"  />
                  </div>
                </div>
                </div>
                <div class="row">
                <div class="col-12 col-md-6 col-lg-6 ">
                  <div class="form-group my-2">
                    <label class="receiveConsultation">Name of clinic <span class="star">*</span> </label>
                    <input type="text" v-model="this.clinic_name" class="form-control" name="clinic_name" id="clinic_name"  
                      />
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 ">
                  <div class="form-group my-2">
                    <label class="receiveConsultation">Short name of clinic </label>
                    <input type="text" v-model="this.short_name" class="form-control" name="short_name" id="short_name"  
                      />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6 col-lg-6 d-block d-lg-none">
                  <div class="form-group my-2">
                    <label class="receiveConsultation">Clinic phone number <span class="star">*</span></label>
                    <input type="text" maxlength="10" v-model="this.coMobile" class="form-control" name="clinic_phone_number"
                       id="mobile"  />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group my-2">
                    <label class="receiveConsultation">Address 1 <span class="star">*</span></label>
                    <input  type="text" v-model="this.current_address" class="form-control" name="current_address" id="current_address" >
                  </div>
                </div>
              </div>
              <div class="row">
              <div class="col-6">
                  <div class="form-group my-2">
                    <label class="receiveConsultation">Address 2</label>
                    <input  type="text" v-model="this.option_address" class="form-control" name="option_address" id="option_address" >
                  </div>
                </div>
              </div>
          
              <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                  <div class="form-group my-2">
                    <label class="receiveConsultation">Pincode <span class="star">*</span></label>
                    <input type="text" v-model="this.coPincode" maxlength="6" class="form-control" name="pincode"
                       id="pincode"   />
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 d-none d-lg-block">
                  <div class="form-group my-2">
                    <label class="receiveConsultation">Clinic phone number <span class="star">*</span></label>
                    <input type="text" maxlength="10" v-model="this.coMobile" class="form-control" name="clinic_phone_number"
                       id="mobile" />
                  </div>
                </div>
              </div>
            
            </div>
          </div>
    
        </div>
        <!-- <div class="col-12 col-md-6 col-lg-6 mt-3">
          <button class="btn btn-blue-color text-white" @click=" createConsultation()"
           style="width:100%">{{ "Make my profile visible" }}
          </button>
        </div> -->
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    props: ["docData"],
    data() {
      return {
        clinicForms: [],
        isActive:false,
        coData: {},
        clinicConsult: [],
        clinicnaOthersId: '',
        clinic_website: "",
        short_name: "",
        clinic_name:"",
        current_address:"",
        option_address:"",
        clinicName:"",
        clinicMobile:"",
        coPincode: "",
        coMobile:"",
        editbutton: false,
        currentlyEditedClinic:{},
        currentlyEditedPincodes: {},
        websiteValidationForm: false,
        payloadArray:[],
        nameRegex:/^[A-Za-z.\s]+$/,
        pincodeValidation:/^[1-9](\s*\d){5}$/,
        mobileValidation:/^[6-9]\d{9}$/,
        numberRegex:/^[1-9][0-9]*$/,
        websiteRegex:/^(https?|ftp):\/\/(www\.)?[^\s/$.?#].[^\s]*$/i,
        clinicConsultationData:{}
      };
    },
    computed: {
      
    areFieldsClinicEmpty(){
        const isWebsiteValid = !this.clinic_website || this.websiteRegex.test(this.clinic_website);
      return (
        !this.nameRegex.test(this.clinic_name) ||
        !this.current_address||
        !this.pincodeValidation.test(this.coPincode) ||
        !this.mobileValidation.test(this.coMobile)||
        !isWebsiteValid
      );
    }
    },
    methods: {

      GoSetting() {
        window.scrollTo(0, 0);
        this.$router.push(`/clinicianothers/mobile-settings`);
      },
   async createConsultation() {
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
            if (!this.clinic_name) {
                this.$swal("Please enter the clinic name!");
                return
            }
            if (!this.current_address) {
                this.$swal("Please enter the address!");
                return
            }
            if (!this.coMobile) {
                this.$swal("Please enter the mobile!");
                return
            }
            if (!this.coPincode) {
                this.$swal("Please enter the pincode!");
                return
            }
        if(this.areFieldsClinicEmpty){ 
      let clinicPayload = {
            clinic_website: this.clinic_website,
            clinic_name: this.clinic_name,
            short_name:this.short_name,
            pincode: this.coPincode,
            mobile: this.coMobile,
            current_address:this.current_address,
            option_address:this.option_address,
            clinician_others_id: this.clinicnaOthersId,
            role : 'clinician-others'
          };
          await axios
            .post(`${BASE_API_URL}/clinic-consultation`, clinicPayload, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              this.$swal("Record is created");
            //   this.getClinicConsultation() ;
              return response.data
            }
            )
            .catch((error) => {
              return error
              });
      
            this.clinicForms=[]
          }
   
      },
      getClinicConsultation() {
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
          .get(`${BASE_API_URL}/clinic-consultation`)
          .then((response) => {
            this.clinicConsult = response.data;
              const createdData = this.clinicConsult.filter(item => {
                  return item.clinician_others_id?.id === this.clinicnaOthersId && item.role == 'clinician-others'
                  });
                  const getConsultation = this.clinicConsult.filter(item => {
                  return item?.id == this.coData?.clinic_hcp_id
                  });
                  const getClinicAdmin = this.clinicConsult.filter(item => {
                  return item?.id == this.coData?.clinic_admin_id
                  });

                this.clinicConsultationData =createdData.length > 0 ? createdData[0] : null ||
                           getConsultation.length > 0 ? getConsultation[0] : null||
                           getClinicAdmin.length > 0?getClinicAdmin[0] : null
            
              this.clinic_name = this.clinicConsultationData.clinic_name,
              this.short_name = this.clinicConsultationData.short_name,
              this.clinic_website = this.clinicConsultationData.clinic_website,
              this.coPincode = this.clinicConsultationData.pincode,
              this.coMobile = this.clinicConsultationData.mobile
              this.current_address =this.clinicConsultationData.current_address
    })
      },
      async getClinicianOthersData() {
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        await axios
          .get(`${BASE_API_URL}/clinician-others/${this.clinicnaOthersId}/one`)
          .then((response) => {
            this.coData = response.data
            this.coPincode = this.coData.pincode
            this.coMobile= this.coData.mobile
          })
      },

    },
    created: async function () {
      let clinicianOthersID = localStorage.getItem('clinician-others-id');
       this.clinicnaOthersId = JSON.parse(clinicianOthersID);
      await this.getClinicianOthersData();
     await this.getClinicConsultation();
    },
  };
  </script>
  <style>
  .allfieldcontent{
  color: red;
  margin-top: 15px
  }
  .star {
      color: red;
      font-size: 20px;
  }
  .hideOpacity{
    opacity: 0;
  }
  .yes-button,
  .no-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .yes-button {
    background-color: #38761D;
    color: #fff;
  }
  
  .no-button {
    background-color: #FF9900;
    color: #fff;
  }
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2) !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popup-content {
    text-align: center;
    background: #fff;
    width: 400px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  .cross-icon{
    font-size:25px;
    color: #cd381c;
    cursor: pointer;
    float: right;
  }
  .clinicCreateBtn{
    color: #00979e !important;
    cursor: pointer;
  }

  button#btn_clinicconsult_add {
      border-color: transparent;
  }
  
  .disableCreateBtn{
    color:  #dddddd !important;
    pointer-events: none;
    cursor: auto; 
  }
  .clinicTextAlign input{
    text-align: left !important;;
  }
  .box-shadow2 {
    box-shadow: 0px 0px 0px 1px #00979e, 0px 0px 0px 0px #00979e !important;
  }
  .cardBorder {
    padding: 15px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    background-color: #fff;
    border-radius: 20px;
  }
  /* .receiveConsultation {
    color: #34989f;
  }
  
  .checkbox-inline {
    color: #34989f;
  } */
  .checkbox-inline.heading {
    font-size: 20px;
    margin-bottom: 0px;
  }
  
  .my-3.subHeading {
    /* color: #34989f; */
    font-size: 18px;
  }
  
  .ms-5.text-color {
    /* color: #34989f; */
    font-size: 18px;
  }
  @media screen and (max-width:991px) {
    .mobileStyleDoctor {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 6rem;
    }
    .mypracticeMobile {
      margin-left: 20px;
      /* margin-bottom: 10px; */
      margin-top: 1rem;
      color: #000;
      font-weight: 550;
      font-size: 21px;
    }
  }
  </style>
  